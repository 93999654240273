import { Vue } from 'vue-class-component'
import { Watch } from 'vue-property-decorator'
import { useRoute, Router } from 'vue-router'
import CommonFunctions from '@/components/Utility/Common'
import BaseFunctions from '@/components/Utility/Base'
import { Competitions } from '@/services/CompetitionsDataService'
import MembersDataService from '@/services/MembersDataService'
import { SysCompetitionResults, SysForeignCompetitionResults, SysOldCompetitionResults } from '@/types/SysCompetition'
import SysMember from '@/types/SysMember'
import ClubsDataService from '@/services/ClubsDataService'
import SysClub from '@/types/SysClub'

type playerResultsDetailsType = { competitionName: string; rowName: string, date: string; placement: string; points: number, genderId: number}
type playerRankingsType = { memberId: number | null; license: number; memberName: string; clubName: string; clubId: number | null; clubSlug: string; total: number; activeLicense: boolean; }
type dataReturnType = { error: any; results: any; members: any; foreignResults: any; oldResults: any }

export default class theRankings extends Vue {
  private todaysDate = new Date().toISOString().split('T')[0]
  private ranking = ''
  private yearPeriod = this.lastYear()
  error: any = null
  // private topPlayers: any[] = []
  private members: SysMember[] = []
  private results: SysCompetitionResults[] = []
  private foreignResults: SysForeignCompetitionResults[] = []
  private oldResults: SysOldCompetitionResults[] = []
  private extraParameter = ''
  private extraForeignParameter = ''
  private extraOldParameter = ''
  private pointsAtIndex = 0
  private memberResultsModal = false
  private aSeriesPlayers = 0 // A series is now renamed to Elite series.
  private bSeriesPlayers = 0 // B series is now renamed to A series.
  selectedResultor = { title: 'Vis alt', value: 'vis_alt' }
  promisesToExecute: any = []

  resultorOptions = [
    { title: 'Vis alt', value: 'vis_alt' },
    { title: 'Nationale', value: 'Staevne_resultaters' },
    { title: 'Udenlandske', value: 'Ud_resultats' }
  ]

  route = useRoute()
  playerRankings: playerRankingsType[] = []
  aPlayersRanked: playerRankingsType[] = [] // A series players is now renamed to Elite series players.
  bPlayersRanked: playerRankingsType[] = [] // B series players is now renamed to A series players.
  cPlayersRanked: playerRankingsType[] = []
  memberDetails = { name: '', clubName: '' }
  rankingDetails: playerResultsDetailsType[] = []

  readonly name : string = 'theRankings'
  data (): dataReturnType {
    return {
      error: this.error,
      results: this.results,
      members: this.members,
      foreignResults: this.foreignResults,
      oldResults: this.oldResults
    }
  }

  // @Watch('memberResultsModal')
  // onModalStatusChange (toggleValue: boolean) : void {
  //   if (toggleValue === false) {
  //     this.memberData = []
  //     this.memberResults = []
  //   }
  // }

  @Watch('selectedResultor')
  onSelectedResultorValueChange (): void {
    if (this.selectedResultor.value) {
      this.retrieveRankings()
    }
    CommonFunctions.scrollToTop()
  }

  @Watch('$route', { immediate: true, deep: true })
  onUrlChange () : void {
    this.retrieveRankings()
  }

  public expiredLicense (status: boolean, expiresDate: string | null) : boolean {
    const tempDate = (expiresDate === null ? '1970-01-01' : expiresDate)

    if (status === true && Date.parse(this.todaysDate) < Date.parse(tempDate)) {
      return false
    }

    return true
  }

  // public bRowCompetition (testVal : string) : boolean {
  //   return testVal.charAt(testVal.length - 1) === 'B'
  // }

  public convertToSlug (text : string) : string {
    return CommonFunctions.slugify(text)
  }

  public generateMemberSlug (id : number, foreName : string, surName : string) : string {
    let memberSlug = ''
    memberSlug = id.toString() + '-' + this.convertToSlug(foreName) + '_' + this.convertToSlug(surName)

    return memberSlug
  }

  public createMemberSlug (id: number, name: string) : string {
    return id.toString() + '-' + this.convertToSlug(name)
  }

  // public aPlayers () : any[] {
  //   return this.topPlayers.filter((element) => { return element.total >= this.pointsAtIndex })
  // }

  // public bPlayers () : any[] {
  //   return this.topPlayers.filter((element) => { return element.total < this.pointsAtIndex })
  // }

  // public findingTopPlayers () : void {
  //   this.rankingInformation = []
  //   this.topPlayers = []
  //   let memberIDs = ''
  //   for (const result of this.results) {
  //     this.rankingInformation.push({
  //       id: (result.medlem_id.id === undefined ? 0 : Number(result.medlem_id.id)),
  //       total: (result.medlem_id.id === undefined ? 0 : this.calcTotal(Number(result.medlem_id.id)))
  //     })
  //   }
  //   for (const foreignResult of this.foreignResults) {
  //     this.rankingInformation.push({
  //       id: (foreignResult.medlem_id.id === undefined ? 0 : Number(foreignResult.medlem_id.id)),
  //       total: (foreignResult.medlem_id.id === undefined ? 0 : this.calcTotal(Number(foreignResult.medlem_id.id)))
  //     })
  //   }

  //   // Eliminates duplicates from the array
  //   this.rankingInformation = this.rankingInformation.filter((value, index, self) =>
  //     index === self.findIndex((t) =>
  //       t.id === value.id
  //     ))
  //   for (const item of this.rankingInformation) {
  //     memberIDs += '&id=' + Number(item.id).toString()
  //   }

  //   if (memberIDs.length > 5) {
  //     MembersDataService.getAll('', null, memberIDs.substring(1))
  //       .then((response) => {
  //         this.members = response.data
  //         this.topPlayers = this.members.map((member) => {
  //           const haveEqualId = (ranking: any) => ranking.id === member.id
  //           const playerWithEqualId = this.rankingInformation.find(haveEqualId)

  //           return Object.assign({}, member, playerWithEqualId)
  //         })
  //         this.topPlayers.sort((a, b) => b.total - a.total)
  //         this.pointsAtIndex = this.topPlayers[this.aSeriesPlayers - 1].total
  //       })
  //       .catch((err) => {
  //         this.error = err
  //       })
  //   }
  // }

  // public calcMemberTotal () : any[] {
  //   const total: any[] = []

  //   Object.entries(this.memberResults).forEach(([key, val]) => {
  //     total.push((val.series === 'C-rækken' && this.displayGender(val.genderId) !== this.ranking ? 0 : val.points))
  //   })

  //   return total.reduce(function (total, num) { return total + num }, 0)
  // }

  // calcTotal (id : number) : number {
  //   const total: any[] = []

  //   for (const item of this.results) {
  //     if (id === item.medlem_id.id) {
  //       total.push(item.staevne_ranglistepoint)
  //     }
  //   }
  //   for (const item2 of this.foreignResults) {
  //     if (id === item2.medlem_id.id) {
  //       total.push(item2.ud_resultat_ranglistepoint)
  //     }
  //   }

  //   return total.reduce(function (total, num) { return total + num }, 0)
  // }

  public lastYear () : string {
    const d = new Date()
    d.setDate(d.getDate() - 365)

    return d.toISOString().slice(0, 10)
  }

  public getDateLastYear (dateString: string) : string {
    const d = new Date(dateString)
    d.setDate(d.getDate() - 365)
    return d.toISOString().slice(0, 10)
  }

  // public retrieveCompetitionResults (id : number, foreName : string, surName : string, club : string) : void {
  //   this.memberData.push({ playerName: foreName + ' ' + surName, playerClub: club })
  //   this.memberResultsModal = true
  //   Competitions.CompetitionResultsDataService.getAll('', null, `staevne_resultater_dato_gte=${this.yearPeriod}&medlem_id.id=${id.toString()}`)
  //     .then((response) => {
  //       this.tempMemberResults = response.data
  //       if (this.tempMemberResults.length > 0) {
  //         for (const item of this.tempMemberResults) {
  //           this.memberResults.push({
  //             competitionName: item.staevner_id.staevne_navn,
  //             date: item.staevne_resultater_dato,
  //             series: item.staevne_raekker_id.staevne_raekker_navn,
  //             placement: item.staevne_resultater_placering,
  //             points: item.staevne_ranglistepoint,
  //             genderId: item.medlem_id.koen_id
  //           })
  //         }
  //       }
  //       Competitions.CompetitionForeignResultsDataService.getAll('', null, `ud_resultat_dato_gte=${this.yearPeriod}&medlem_id.id=${id.toString()}`)
  //         .then((response) => {
  //           this.tempMemberForeignResults = response.data
  //           if (this.tempMemberForeignResults.length > 0) {
  //             for (const item2 of this.tempMemberForeignResults) {
  //               this.memberResults.push({
  //                 competitionName: item2.ud_staevner_id.ud_staevne_navn,
  //                 date: item2.ud_resultat_dato,
  //                 series: item2.staevne_raekker_id.staevne_raekker_navn,
  //                 placement: item2.ud_resultat_placering,
  //                 points: item2.ud_resultat_ranglistepoint,
  //                 genderId: item2.medlem_id.koen_id
  //               })
  //             }
  //           }
  //           this.memberResults = this.memberResults.filter(value => (value.series.toLowerCase().includes(this.ranking.toLowerCase().substring(0, 3)) || (value.series === 'C-rækken' && this.displayGender(value.genderId) === this.ranking)))
  //           this.memberResults.sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime())
  //         })
  //         .catch((err) => {
  //           this.error = err
  //         })
  //     })
  //     .catch((err) => {
  //       this.error = err
  //     })
  // }

  public displayGender (koenId : number) : string {
    if (koenId === 1) {
      return 'damer'
    } else {
      return 'herrer'
    }
  }

  public toDanishDateString (dateString: string) : string {
    return new Date(dateString).toLocaleDateString('da-DK', { day: '2-digit', month: '2-digit', year: '2-digit' }).replaceAll('.', '/')
  }

  // public retrieveRankingList () : void {
  //   this.ranking = Array.isArray(this.route.params.rankingSlug) ? this.route.params.rankingSlug[0] : this.route.params.rankingSlug

  //   if (this.ranking === 'herrer') {
  //     this.extraParameter = '_where[_or][0][staevne_raekker_id.staevne_raekker_navn_contains]=herre&_where[_or][1][0][staevne_raekker_id.staevne_raekker_navn_contains]=c-rækken&_where[_or][1][1][medlem_id.koen_id]=2'
  //     this.extraForeignParameter = 'staevne_raekker_id.staevne_raekker_navn_contains=herre'
  //     this.aSeriesPlayers = 80
  //     // this.extraParameter = '_where[_or][0][staevne_raekker_id.staevne_raekker_navn]=Herre A&_where[_or][1][staevne_raekker_id.staevne_raekker_navn]=Herre B&_where[_or][2][0][staevne_raekker_id.staevne_raekker_navn]=C-rækken&_where[_or][2][1][medlem_id.koen_id]=2'
  //     // this.extraParameter = `staevne_raekker_id.staevne_raekker_navn=Herre A&staevne_raekker_id.staevne_raekker_navn=Herre B`
  //   }
  //   if (this.ranking === 'damer') {
  //     this.extraParameter = '_where[_or][0][staevne_raekker_id.staevne_raekker_navn_contains]=dame&_where[_or][1][0][staevne_raekker_id.staevne_raekker_navn_contains]=c-rækken&_where[_or][1][0][medlem_id.koen_id]=1'
  //     this.extraForeignParameter = 'staevne_raekker_id.staevne_raekker_navn_contains=dame'
  //     this.aSeriesPlayers = 40
  //     // this.extraParameter = '_where[_or][0][staevne_raekker_id.staevne_raekker_navn]=Dame A&_where[_or][1][staevne_raekker_id.staevne_raekker_navn]=Dame B&_where[_or][2][0][staevne_raekker_id.staevne_raekker_navn]=C-rækken&_where[_or][2][1][medlem_id.koen_id]=1'
  //     // this.extraParameter = 'staevne_raekker_id.staevne_raekker_navn=Dame A&staevne_raekker_id.staevne_raekker_navn=Dame B'
  //   }
  //   if (this.ranking === 'drenge') {
  //     this.extraParameter = '_where[_or][0][staevne_raekker_id.staevne_raekker_navn_contains]=drenge&_where[_or][1][staevne_raekker_id.staevne_raekker_navn_contains]=junior'
  //     this.extraForeignParameter = 'staevne_raekker_id.staevne_raekker_navn_contains=drenge'
  //     this.aSeriesPlayers = 16
  //     // this.extraParameter = 'staevne_raekker_id.staevne_raekker_navn=Drenge A&staevne_raekker_id.staevne_raekker_navn=Drenge B'
  //   }
  //   if (this.ranking === 'piger') {
  //     this.extraParameter = 'staevne_raekker_id.staevne_raekker_navn_contains=pige'
  //     this.extraForeignParameter = 'staevne_raekker_id.staevne_raekker_navn_contains=pige'
  //     this.aSeriesPlayers = 16
  //     // this.extraParameter = 'staevne_raekker_id.staevne_raekker_navn=Pige A&staevne_raekker_id.staevne_raekker_navn=Pige B'
  //   }
  //   if (this.ranking === 'paradart') {
  //     this.extraParameter = 'staevne_raekker_id.staevne_raekker_navn_contains=para'
  //     this.extraForeignParameter = 'staevne_raekker_id.staevne_raekker_navn_contains=paradart'
  //     // this.extraParameter = 'staevne_raekker_id.staevne_raekker_navn=Paradart Single'
  //   }

  //   Competitions.CompetitionResultsDataService.getAll('', null, (this.extraParameter + `&staevne_resultater_dato_gte=${this.yearPeriod}&medlem_id_ne=null&medlem_id.id_ne=17&staevne_ranglistepoint_ne=0`))
  //     .then((response) => {
  //       this.results = response.data
  //       Competitions.CompetitionForeignResultsDataService.getAll('', null, (this.extraForeignParameter + `&ud_resultat_dato_gte=${this.yearPeriod}&medlem_id_ne=null&medlem_id.id_ne=17&ud_resultat_ranglistepoint_ne=0`))
  //         .then((response) => {
  //           this.foreignResults = response.data
  //           this.$forceUpdate()
  //           this.findingTopPlayers()
  //         })
  //         .catch((err) => {
  //           this.error = err
  //         })
  //     })
  //     .catch((err) => {
  //       this.error = err
  //     })
  // }

  public async retrieveRankings () : Promise<void> {
    // First attempt to get date from the server.
    let dateResponse: any
    // try {
    //   dateResponse = (await BaseFunctions.getDatetimeFromServer()).data
    // } catch (err) {
    //   console.log(err)
    // }
    this.todaysDate = new Date().toISOString().split('T')[0]
    // this.todaysDate = new Date(dateResponse).toISOString().split('T')[0]
    this.yearPeriod = this.getDateLastYear(this.todaysDate)
    this.ranking = Array.isArray(this.route.params.rankingSlug) ? this.route.params.rankingSlug[0] : this.route.params.rankingSlug
    this.bSeriesPlayers = 0
    // console.log('[retrieveRankings()] ranking = ' + this.ranking)

    switch (this.ranking) {
      case 'herrer':
        // this.extraParameter = '_where[_or][0][staevne_raekker_id.staevne_raekker_navn_contains]=herre&_where[_or][1][0][staevne_raekker_id.staevne_raekker_navn_contains]=c-rækken&_where[_or][1][1][medlem_id.koen_id]=2'
        this.extraParameter = '_where[_or][0][staevne_raekker_id.staevne_raekker_navn_contains]=herre&_where[_or][1][staevne_raekker_id.staevne_raekker_navn_contains]=aaben&_where[_or][2][0][staevne_raekker_id.staevne_raekker_navn_contains]=c-rækken&_where[_or][2][1][medlem_id.koen_id]=2'
        this.extraForeignParameter = 'staevne_raekker_id.staevne_raekker_navn_contains=herre'
        this.extraOldParameter = 'Klasse=her'
        this.aSeriesPlayers = 32
        this.bSeriesPlayers = 180

        break
      case 'damer':
        this.extraParameter = '_where[_or][0][staevne_raekker_id.staevne_raekker_navn_contains]=dame&_where[_or][1][0][staevne_raekker_id.staevne_raekker_navn_contains]=c-rækken&_where[_or][1][1][medlem_id.koen_id]=1'
        // this.extraParameter = '_where[_or][0][staevne_raekker_id.staevne_raekker_navn_contains]=dame&_where[_or][1][staevne_raekker_id.staevne_raekker_navn_contains]=aaben&_where[_or][2][0][staevne_raekker_id.staevne_raekker_navn_contains]=c-rækken&_where[_or][2][1][medlem_id.koen_id]=1'
        this.extraForeignParameter = 'staevne_raekker_id.staevne_raekker_navn_contains=dame'
        this.extraOldParameter = 'Klasse=dam'
        this.aSeriesPlayers = 16
        this.bSeriesPlayers = 90

        break
      case 'drenge':
        this.extraParameter = '_where[_or][0][staevne_raekker_id.staevne_raekker_navn_contains]=drenge&_where[_or][1][staevne_raekker_id.staevne_raekker_navn_contains]=junior'
        this.extraForeignParameter = 'staevne_raekker_id.staevne_raekker_navn_contains=drenge'
        this.extraOldParameter = 'Klasse=dre'
        this.aSeriesPlayers = 16

        break
      case 'piger':
        this.extraParameter = 'staevne_raekker_id.staevne_raekker_navn_contains=pige'
        this.extraForeignParameter = 'staevne_raekker_id.staevne_raekker_navn_contains=pige'
        this.extraOldParameter = 'Klasse=pig'
        this.aSeriesPlayers = 16

        break
      case 'paradart':
        this.extraParameter = 'staevne_raekker_id.staevne_raekker_navn_contains=para'
        this.extraForeignParameter = 'staevne_raekker_id.staevne_raekker_navn_contains=paradart'
        this.extraOldParameter = 'Klasse=par'
        this.aSeriesPlayers = 0

        break
    }
    console.log('this.extraParameter', this.extraParameter)

    if (this.extraParameter === '' || this.extraForeignParameter === '' || this.extraOldParameter === '') {
      return
    }

    const competitionPromise = Competitions.CompetitionResultsDataService.getAll('', null, (this.extraParameter + `&staevne_resultater_dato_gte=${this.yearPeriod}&medlem_id_ne=null&medlem_id.id_ne=17&staevne_ranglistepoint_ne=0`))
    const foreignCompetitionPromise = Competitions.CompetitionForeignResultsDataService.getAll('', null, (`&ud_resultat_dato_gte=${this.yearPeriod}&medlem_id_ne=null&medlem_id.id_ne=17&ud_resultat_ranglistepoint_ne=0`))

    const oldCompetitionPromise = Competitions.OldCompetitionResultsDataService.getAll('', null, this.extraOldParameter + `&Dato_gte=${this.yearPeriod}`)
    const clubsPromise = ClubsDataService.getAll() // After the old results are no longer used, can be removed

    if (this.selectedResultor.value === 'vis_alt') {
      this.promisesToExecute = [competitionPromise, foreignCompetitionPromise]
    } else if (this.selectedResultor.value === 'Staevne_resultaters') {
      this.promisesToExecute = [competitionPromise]
    } else if (this.selectedResultor.value === 'Ud_resultats') {
      this.promisesToExecute = [foreignCompetitionPromise]
    }
    this.promisesToExecute.push(oldCompetitionPromise, clubsPromise)

    await Promise.all(this.promisesToExecute)
      .then(async (response: any) => {
        // Note: commented code an refactor the code keep this as is as of now

        // this.results = response[0]?.data as SysCompetitionResults[]
        // this.foreignResults = response[1]?.data as SysForeignCompetitionResults[]
        // this.oldResults = response[2]?.data as SysOldCompetitionResults[]
        // const tempClubs = response[3]?.data as SysClub[]

        let index = 0

        if (this.selectedResultor.value === 'vis_alt') {
          this.results = response[index++]?.data as SysCompetitionResults[]
          this.foreignResults = response[index++]?.data as SysForeignCompetitionResults[]
        } else if (this.selectedResultor.value === 'Staevne_resultaters') {
          this.results = response[index++]?.data as SysCompetitionResults[]
          this.foreignResults = []
        } else if (this.selectedResultor.value === 'Ud_resultats') {
          this.results = []
          this.foreignResults = response[index++]?.data as SysForeignCompetitionResults[]
        }

        this.oldResults = response[index++]?.data as SysOldCompetitionResults[]
        const tempClubs = response[index]?.data as SysClub[]

        function findCLubId (clubName: string) : number | null {
          const index = tempClubs.findIndex(el => el.klubber_klubnavn.toLowerCase() === clubName.toLowerCase())

          if (index !== -1) {
            return Number(tempClubs[index].id)
          }
          return null
        }

        function findClubName (clubId: number, slug: boolean) {
          let retVal = ''
          const index = tempClubs.findIndex(el => Number(el.id) === Number(clubId))

          if (index !== -1) {
            retVal = (slug ? tempClubs[index].klubber_slug : tempClubs[index].klubber_klubnavn)
          }
          return retVal
        }

        function activeLicense (licenseEnd: string | null, today: string) : boolean {
          if (licenseEnd === null) {
            return false
          }
          const todaysDate = new Date(today).getTime()
          const licenseStop = new Date(licenseEnd).getTime()
          if (licenseStop < todaysDate) {
            return false
          }
          return true
        }

        // console.log('[retrieveRankings()] results = ' + JSON.stringify(this.results))
        // console.log('[retrieveRankings()] oldresults = ' + JSON.stringify(this.oldResults))
        this.playerRankings = []

        // Process information from regular competition results.
        for (const result of this.results) {
          const index = this.playerRankings.findIndex(el => Number(el.memberId) === Number(result.medlem_id.id))

          if (index === -1 && result.staevne_raekker_id.staevne_raekker_navn !== undefined && this.ranking !== undefined && (result.staevne_raekker_id.staevne_raekker_navn.toLowerCase().includes(this.ranking.toLowerCase().substring(0, 3)) || (this.ranking.toLowerCase() === 'herrer' && result.staevne_raekker_id.staevne_raekker_navn.toLowerCase().includes('aaben')) || ((this.ranking.toLowerCase() === 'herrer' || this.ranking.toLowerCase() === 'damer') && result.staevne_raekker_id.staevne_raekker_navn.toLowerCase().includes('c-rækken')) || ((this.ranking.toLowerCase().includes('dreng') || this.ranking.toLowerCase().includes('boy') || this.ranking.toLowerCase().includes('pige') || this.ranking.toLowerCase().includes('girl') || this.ranking.toLowerCase().includes('junior')) && result.staevne_raekker_id.staevne_raekker_navn.toLowerCase().includes('juniorsingle')))) {
            this.playerRankings.push({
              memberId: Number(result.medlem_id.id),
              memberName: result.staevne_resultater_navn,
              license: (result.medlem_id.medlem_licens === null ? 0 : result.medlem_id.medlem_licens),
              clubName: (result.medlem_id.klubber_id === null ? 'Ingen klub' : findClubName(result.medlem_id.klubber_id, false)),
              clubId: result.medlem_id.klubber_id,
              clubSlug: (result.medlem_id.klubber_id === null ? '' : findClubName(result.medlem_id.klubber_id, true)),
              total: this.results.filter(el => Number(el.medlem_id.id) === Number(result.medlem_id.id) && (el.staevne_raekker_id.staevne_raekker_navn.toLowerCase().includes(this.ranking.toLowerCase().substring(0, 3)) || (this.ranking.toLowerCase() === 'herrer' && el.staevne_raekker_id.staevne_raekker_navn.toLowerCase().includes('aaben')) || ((this.ranking.toLowerCase() === 'herrer' || this.ranking.toLowerCase() === 'damer') && el.staevne_raekker_id.staevne_raekker_navn.toLowerCase().includes('c-rækken')) || ((this.ranking.toLowerCase().includes('dreng') || this.ranking.toLowerCase().includes('boy') || this.ranking.toLowerCase().includes('pige') || this.ranking.toLowerCase().includes('girl') || this.ranking.toLowerCase().includes('junior')) && el.staevne_raekker_id.staevne_raekker_navn.toLowerCase().includes('juniorsingle')))).reduce(function (acc, obj) { return acc + obj.staevne_ranglistepoint }, 0) + this.foreignResults.filter(el => Number(el.medlem_id.id) === Number(result.medlem_id.id) && (el.staevne_raekker_id.staevne_raekker_navn.toLowerCase().includes(this.ranking.toLowerCase().substring(0, 3)) || ((this.ranking.toLowerCase() === 'herrer' || this.ranking.toLowerCase() === 'damer') && el.staevne_raekker_id.staevne_raekker_navn.toLowerCase().includes('c-rækken')) || ((this.ranking.toLowerCase().includes('dreng') || this.ranking.toLowerCase().includes('boy') || this.ranking.toLowerCase().includes('pige') || this.ranking.toLowerCase().includes('girl') || this.ranking.toLowerCase().includes('junior')) && el.staevne_raekker_id.staevne_raekker_navn.toLowerCase().includes('juniorsingle')))).reduce(function (acc, obj) { return acc + obj.ud_resultat_ranglistepoint }, 0) + this.oldResults.filter(el => Number(el.Licens) === Number(result.medlem_id.medlem_licens)).reduce(function (acc, obj) { return acc + obj.Point }, 0),
              activeLicense: activeLicense(result.medlem_id.medlem_licens_slut, this.todaysDate)
            })
          }
        }

        // Process information from foreign players competition results.
        let missingMemberNamesParam = ''
        for (const foreign of this.foreignResults) {
          const index = this.playerRankings.findIndex(el => Number(el.memberId) === Number(foreign.medlem_id.id))

          if (index === -1 && this.ranking !== undefined && (foreign.staevne_raekker_id.staevne_raekker_navn.toLowerCase().includes(this.ranking.toLowerCase().substring(0, 3)) || (this.ranking.toLowerCase() === 'herrer' && foreign.staevne_raekker_id.staevne_raekker_navn.toLowerCase().includes('aaben')) || ((this.ranking.toLowerCase().includes('dreng') || this.ranking.toLowerCase().includes('boy') || this.ranking.toLowerCase().includes('pige') || this.ranking.toLowerCase().includes('girl') || this.ranking.toLowerCase().includes('junior')) && foreign.staevne_raekker_id.staevne_raekker_navn.toLowerCase().includes('juniorsingle')))) {
            this.playerRankings.push({
              memberId: Number(foreign.medlem_id.id),
              license: (foreign.medlem_id.medlem_licens === null ? 0 : foreign.medlem_id.medlem_licens),
              memberName: '',
              clubName: (foreign.medlem_id.klubber_id === null ? 'Ingen klub' : findClubName(foreign.medlem_id.klubber_id, false)),
              clubSlug: (foreign.medlem_id.klubber_id === null ? '' : findClubName(foreign.medlem_id.klubber_id, true)),
              clubId: foreign.medlem_id.klubber_id,
              total: this.foreignResults.filter(el => Number(el.medlem_id.id) === Number(foreign.medlem_id.id) && (el.staevne_raekker_id.staevne_raekker_navn.toLowerCase().includes(this.ranking.toLowerCase().substring(0, 3)) || (this.ranking.toLowerCase() === 'herrer' && el.staevne_raekker_id.staevne_raekker_navn.toLowerCase().includes('aaben')) || ((this.ranking.toLowerCase() === 'herrer' || this.ranking.toLowerCase() === 'damer') && el.staevne_raekker_id.staevne_raekker_navn.toLowerCase().includes('c-rækken')) || ((this.ranking.toLowerCase().includes('dreng') || this.ranking.toLowerCase().includes('boy') || this.ranking.toLowerCase().includes('pige') || this.ranking.toLowerCase().includes('girl') || this.ranking.toLowerCase().includes('junior')) && el.staevne_raekker_id.staevne_raekker_navn.toLowerCase().includes('juniorsingle')))).reduce(function (acc, obj) { return acc + obj.ud_resultat_ranglistepoint }, 0) + this.oldResults.filter(el => Number(el.Licens) === Number(foreign.medlem_id.medlem_licens)).reduce(function (acc, obj) { return acc + obj.Point }, 0),
              activeLicense: activeLicense(foreign.medlem_id.medlem_licens_slut, this.todaysDate)
            })
          }

          missingMemberNamesParam += '&id=' + foreign.medlem_id.id
        }

        // Process information from old competition results.
        for (const old of this.oldResults) {
          const index = this.playerRankings.findIndex(el => Number(el.license) === Number(old.Licens))

          if (index === -1) {
            this.playerRankings.push({
              memberId: null,
              license: old.Licens,
              memberName: old.Navn,
              clubName: old.Klub,
              clubId: findCLubId(old.Klub),
              clubSlug: findCLubId(old.Klub) === null ? '' : findClubName(Number(findCLubId(old.Klub)), true),
              total: this.oldResults.filter(el => Number(el.Licens) === Number(old.Licens)).reduce(function (acc, obj) { return acc + obj.Point }, 0),
              activeLicense: true
            })
          }
        }

        if (missingMemberNamesParam !== '') {
          let tempMembers: SysMember[] = []
          missingMemberNamesParam = missingMemberNamesParam.slice(1)
          // console.log(missingMemberNamesParam)

          try {
            tempMembers = (await MembersDataService.getAll('', null, missingMemberNamesParam)).data as SysMember[]
          } catch (err) {
            console.log(err)
          }

          for (const player of this.playerRankings) {
            if (player.memberName === '') {
              const index = tempMembers.findIndex(el => Number(el.id) === player.memberId)

              if (index !== -1) {
                player.memberName = tempMembers[index].user_id.firstname + ' ' + tempMembers[index].user_id.lastname
              }
            }
          }
        }

        console.log('this.playerRankings', this.playerRankings)

        this.createAandBPlayers(this.aSeriesPlayers, this.bSeriesPlayers)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  // Create the arrays for the ranks (A-, B- and C-rækken; now renamed to Elite-, A- and C-rækken) that are applicable according to the specified function arguments.
  public createAandBPlayers (aplayer: number, bplayer = 0) : void {
    if (aplayer === 0 || this.playerRankings.length <= aplayer) {
      // No B- and C-række are filled situation.
      this.aPlayersRanked = this.playerRankings
      this.bPlayersRanked = []
      this.cPlayersRanked = []
      this.aPlayersRanked.sort((a, b) => b.total - a.total)
    } else {
      if (bplayer === 0 || this.playerRankings.length <= bplayer) {
        // No C-række are filled situation.
        this.playerRankings.sort((a, b) => b.total - a.total)
        const pointsAtIndexAB = this.playerRankings[aplayer - 1].total
        this.aPlayersRanked = this.playerRankings.filter(el => el.total >= pointsAtIndexAB).sort((a, b) => b.total - a.total)
        this.bPlayersRanked = this.playerRankings.filter(el => el.total < pointsAtIndexAB).sort((a, b) => b.total - a.total)
        this.cPlayersRanked = []
        // console.log(this.aPlayersRanked)
        // console.log(this.bPlayersRanked)
      } else {
        // Both A-, B- and C-rækken are filled situation.
        this.playerRankings.sort((a, b) => b.total - a.total)
        const pointsAtIndexAB = this.playerRankings[aplayer - 1].total
        const pointsAtIndexBC = this.playerRankings[bplayer - 1].total
        this.aPlayersRanked = this.playerRankings.filter(el => el.total >= pointsAtIndexAB).sort((a, b) => b.total - a.total)
        this.bPlayersRanked = this.playerRankings.filter(el => (el.total < pointsAtIndexAB && el.total >= pointsAtIndexBC)).sort((a, b) => b.total - a.total)
        this.cPlayersRanked = this.playerRankings.filter(el => el.total < pointsAtIndexBC).sort((a, b) => b.total - a.total)
        // console.log(this.aPlayersRanked)
        // console.log(this.bPlayersRanked)
        // console.log(this.cPlayersRanked)
      }
    }
  }

  public retrieveDateTimeFromServer () : void {
    BaseFunctions.getDatetimeFromServer()
      .then((response) => {
        this.todaysDate = new Date(response.data).toISOString().split('T')[0]
        // this.todaysDate = response.data.split('T')[0]
      })
      .catch((err) => {
        this.error = err
      })
  }

  public async memberResultsDetails (memberId: number | null, license: number | null, name: string, club: string) {
    this.memberDetails = { name: name, clubName: club }
    this.rankingDetails = []

    let resultsPromise
    let foreingResultsPromise
    // let oldResultsPromise

    if (memberId !== null) {
      resultsPromise = Competitions.CompetitionResultsDataService.getAll('', null, `medlem_id.id=${memberId}&staevne_resultater_dato_gte=${this.yearPeriod}`)
      foreingResultsPromise = Competitions.CompetitionForeignResultsDataService.getAll('', null, `medlem_id.id=${memberId}&ud_resultat_dato_gte=${this.yearPeriod}`)
    }
    // if (license !== null) {
    //   oldResultsPromise = Competitions.OldCompetitionResultsDataService.getAll('', null, `Licens=${license}&Dato_gte=${this.yearPeriod}`)
    // }

    // await Promise.all([resultsPromise, foreingResultsPromise, oldResultsPromise])
    await Promise.all([resultsPromise, foreingResultsPromise])
      .then((response) => {
        const results = (response[0] !== undefined ? response[0].data : []) as SysCompetitionResults[]
        const foreignResults = (response[1] !== undefined ? response[1].data : []) as SysForeignCompetitionResults[]
        // const oldResults = (response[2] !== undefined ? response[2].data : []) as SysOldCompetitionResults[]
        // const oldResults: any[] = []

        function getPlacement (placement: number) : string {
          let retVal = ''
          switch (placement) {
            case 1:
              retVal = '1'
              break
            case 2:
              retVal = '2'
              break
            case 3:
              retVal = '3-4'
              break
            case 5:
              retVal = '5-8'
              break
            case 9:
              retVal = '9-16'
              break
            case 17:
              retVal = '17-32'
              break
            default:
              retVal = ''
          }
          return retVal
        }

        if (results.length > 0) {
          for (const item of results) {
            // if (item.staevne_raekker_id.staevne_raekker_navn.toLocaleLowerCase().includes(this.ranking.toLowerCase().substring(0, 3)) || (item.staevne_raekker_id.staevne_raekker_navn === 'C-rækken' && this.displayGender(item.medlem_id.koen_id) === this.ranking)) {
            if (item.staevne_raekker_id.staevne_raekker_navn.toLowerCase().includes(this.ranking.toLowerCase().substring(0, 3)) || (this.ranking.toLowerCase() === 'herrer' && item.staevne_raekker_id.staevne_raekker_navn.toLowerCase().includes('aaben')) || (item.staevne_raekker_id.staevne_raekker_navn.toLowerCase().includes('c-rækken') && this.displayGender(item.medlem_id.koen_id) === this.ranking.toLowerCase()) || (item.staevne_raekker_id.staevne_raekker_navn.toLowerCase().includes('juniorsingle') && (this.ranking.toLowerCase().includes('dreng') || this.ranking.toLowerCase().includes('boy') || this.ranking.toLowerCase().includes('pige') || this.ranking.toLowerCase().includes('girl') || this.ranking.toLowerCase().includes('junior')))) {
              this.rankingDetails.push({
                competitionName: item.staevner_id.staevne_navn,
                rowName: item.staevne_raekker_id.staevne_raekker_navn,
                date: item.staevne_resultater_dato,
                placement: item.staevne_resultater_placering,
                points: item.staevne_ranglistepoint,
                genderId: item.medlem_id.koen_id
              })
            }
          }
        }

        if (foreignResults.length > 0) {
          for (const item of foreignResults) {
            if (item.staevne_raekker_id.staevne_raekker_navn.toLowerCase().includes(this.ranking.toLowerCase().substring(0, 3)) || (this.ranking.toLowerCase() === 'herrer' && item.staevne_raekker_id.staevne_raekker_navn.toLowerCase().includes('aaben')) || (item.staevne_raekker_id.staevne_raekker_navn.toLowerCase().includes('juniorsingle') && (this.ranking.toLowerCase().includes('dreng') || this.ranking.toLowerCase().includes('boy') || this.ranking.toLowerCase().includes('pige') || this.ranking.toLowerCase().includes('girl') || this.ranking.toLowerCase().includes('junior')))) {
              this.rankingDetails.push({
                competitionName: item.ud_staevner_id.ud_staevne_navn,
                rowName: item.staevne_raekker_id.staevne_raekker_navn,
                date: item.ud_resultat_dato,
                placement: item.ud_resultat_placering,
                points: item.ud_resultat_ranglistepoint,
                genderId: item.medlem_id.koen_id
              })
            }
          }
        }

        // if (oldResults.length > 0) {
        //   for (const item of oldResults) {
        //     if (this.ranking.toLowerCase().includes(item.Klasse.toString())) {
        //       this.rankingDetails.push({
        //         competitionName: item.Staevne,
        //         rowName: '',
        //         date: item.Dato,
        //         placement: getPlacement(item.Placering),
        //         points: item.Point,
        //         genderId: 0
        //       })
        //     }
        //   }
        // }

        this.memberResultsModal = true
        this.rankingDetails.sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime())
      })
      .catch((err) => {
        console.log(err)
      })
  }

  public calcDetailsTotal (resultsArray: playerResultsDetailsType[]) : number {
    return resultsArray.reduce(function (acc, obj) { return acc + obj.points }, 0)
  }

  async mounted () : Promise<void> {
    // this.retrieveRankingList()
    // this.retrieveDateTimeFromServer()
    // Fixed loading issue.(retrieveRankings() function call from Watch)
    this.retrieveRankings()
  }
}
